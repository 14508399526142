/* General Table Container */
.csv-report-table-container {
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.table-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
}

.table-header h2 {
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
}

/* Table Wrapper */
.csv-table-wrapper {
    overflow-x: auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table */
.csv-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.csv-table thead {
    background-color: #007bff;
    color: white;
}

.csv-table thead th {
    padding: 12px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.csv-table tbody td {
    padding: 8px;
    font-size: 14px;
    color: #555;
    text-align: center;
    vertical-align: middle;
}

/* Prevent Text Wrapping for Date Range */
.csv-table tbody td:nth-child(6) {
    white-space: nowrap;
    text-align: center;
}

/* Table Rows */
.csv-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.csv-table tbody tr:hover {
    background-color: #dee7ef;
}

/* Badges */
.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    width: 6rem
}

.badge-completed {
    background-color: #28a745;
}

.badge-progress {
    background-color: #ffc107;
}

.badge-failed {
    background-color: hsl(0, 100%, 50%);
}
/* Actions */
.actions {
    display: flex;
    justify-content: center;
    /* Center-aligns the actions */
    align-items: center;
    /* Ensures vertical alignment */
    gap: 2px;
    /* Adds spacing between icons */
}

.actions .icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    /* Ensures uniform size for the button */
    height: 36px;
}

.actions .icon-button svg {
    font-size: 20px;
    /* Sets consistent size for the icons */
}


/* Responsive Adjustments */
@media (max-width: 768px) {

    /* Reduce font size and padding for smaller screens */
    .csv-table tbody td {
        font-size: 12px;
        padding: 6px;
    }

    /* Smaller badges for mobile screens */
    .badge {
        font-size: 10px;
        padding: 3px 6px;
    }
}